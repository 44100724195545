import axios from "axios";
import ThemeMaker from "../../components/thememaker";
import { config } from "../../utils/config";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Papa from 'papaparse';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as XLSX from 'xlsx';


const Sstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: "10px",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
};
export default function Addroducttype() {
  const { apiEndpoint } = config;
  const [isSubmitting, setisSubmitting] = useState(false);
  const params: any = useParams()
  const [ptData, setptData] = useState({
    name: "",
    ondc_mapping: "",
    status: true
  })

  useEffect(() => {
    if (params?.id) {
      getProductTypeById()
    }
  }, [])



  const catMapping: any = {
    'Fruits and Vegetables': "",
    'Masala & Seasoning': '@ondc/org/statutory_reqs_packaged_commodities',
    'Oil & Ghee': '@ondc/org/statutory_reqs_packaged_commodities',
    'Eggs, Meat & Fish': '@ondc/org/statutory_reqs_packaged_commodities',
    'Cleaning & Household': '@ondc/org/statutory_reqs_packaged_commodities',
    'Bakery, Cakes & Dairy': '@ondc/org/statutory_reqs_prepackaged_food',
    'Pet Care': '@ondc/org/statutory_reqs_packaged_commodities',
    'Stationery': '@ondc/org/statutory_reqs_packaged_commodities',
    'Dairy and Cheese': '@ondc/org/statutory_reqs_prepackaged_food',
    'Snacks, Dry Fruits, Nuts': '@ondc/org/statutory_reqs_prepackaged_food',
    'Pasta, Soup and Noodles': '@ondc/org/statutory_reqs_packaged_commodities',
    'Cereals and Breakfast': '@ondc/org/statutory_reqs_prepackaged_food',
    'Sauces, Spreads and Dips': '@ondc/org/statutory_reqs_prepackaged_food',
    'Chocolates and Biscuits': '@ondc/org/statutory_reqs_prepackaged_food',
    'Cooking and Baking Needs': '@ondc/org/statutory_reqs_packaged_commodities',
    'Tinned and Processed Food': '@ondc/org/statutory_reqs_prepackaged_food',
    'Atta, Flours and Sooji': '@ondc/org/statutory_reqs_packaged_commodities',
    'Rice and Rice Products': '@ondc/org/statutory_reqs_packaged_commodities',
    'Dals and Pulses': '@ondc/org/statutory_reqs_packaged_commodities',
    'Salt, Sugar and Jaggery': '@ondc/org/statutory_reqs_packaged_commodities',
    'Energy and Soft Drinks': '@ondc/org/statutory_reqs_prepackaged_food',
    'Water': "",
    'Tea and Coffee': '@ondc/org/statutory_reqs_packaged_commodities',
    'Fruit Juices and Fruit Drinks': '@ondc/org/statutory_reqs_prepackaged_food',
    'Snacks and Namkeen': '@ondc/org/statutory_reqs_prepackaged_food',
    'Ready to Cook and Eat': '@ondc/org/statutory_reqs_prepackaged_food',
    'Pickles and Chutney': '@ondc/org/statutory_reqs_prepackaged_food',
    'Indian Sweets': '@ondc/org/statutory_reqs_prepackaged_food',
    'Frozen Vegetables': "",
    'Frozen Snacks': '@ondc/org/statutory_reqs_prepackaged_food',
    'Gift Voucher': "",
    'Fragrance': '',
    'Bath Soaps and Gels': '',
    'Hair Oils, Care, and Styling': '',
    'Shampoos and Conditioners': '',
    'Shaving and Grooming': '',
    'Beard Care and Tools': '',
    'Grooming Tools and Accessories': '',
    'Makeup - Nail Care': '',
    'Makeup - Eyes': '',
    'Makeup - Face': '',
    'Makeup - Lips': '',
    'Makeup - Body': '',
    'Makeup - Remover': '',
    'Makeup - Sets and Kits': '',
    'Makeup - Tools and Brushes': '',
    'Makeup - Kits and Combos': '',
    'Skin Care - Face Cleansers': '',
    'Skin Care - Hand and Feet': '',
    'Body Care - Cleansers': '',
    'Body Care - Moisturizers': '',
    'Body Care - Loofah and Other Tools': '',
    'Body Care - Bath Salt and Additives': '',
    'Hair Care - Shampoo, Oils, Conditioners': '',
    'Skin Care - Lotions, Moisturisers, and Creams': '',
    'Skin Care - Oils and Serums': '',
    'Mobile Phone': '',
    'Smart Watch': '',
    'Headset': '',
    'Laptop': '',
    'Desktop': '',
    'Tablet': '',
    'Keyboard': '',
    'Monitor': '',
    'Mouse': '',
    'Power Bank': '',
    'Earphone': '',
    'True Wireless Stereo (TWS)': '',
    'Adapter': '',
    'Cable': '',
    'Extension Cord': '',
    'Audio Accessories': '',
    'Home Audio': '',
    'Microphone': '',
    'Speaker': '',
    'Vehicle Audio': '',
    'Camcorder': '',
    'Camera': '',
    'Camera Bag': '',
    'Batteries': '',
    'Charger': '',
    'Camera Lens': '',
    'Photo Printer': '',
    'Tripod': '',
    'Camera Accessories': '',
    'UPS': '',
    'Networking Device': '',
    'Printer': '',
    'Printer Accessories': '',
    'Storage Drive': '',
    'Pen Drive': '',
    'Memory Card': '',
    'Computer Component': '',
    'Cooling Pad': '',
    'Docking Station': '',
    'Keyboard Guard': '',
    'Laptop Skin': '',
    'Laptop Stand': '',
    'Mousepad': '',
    'Laptop Bag': '',
    'Screen Protector': '',
    'Computer Accessories': '',
    'Computer Software': '',
    'Ebook Reader': '',
    'Tablet Accessories': '',
    'Gaming Controller': '',
    'Gaming Chair': '',
    'Gaming Accessories': '',
    'Gaming Console': '',
    'Video Games': '',
    'Mobile Cover': '',
    'Mobile Mount': '',
    'Mobile Screen Guard': '',
    'Selfie Stick': '',
    'Mobile Skin Sticker': '',
    'Biometrics': '',
    'Home Alarm': '',
    'Home Automation': '',
    'Smart Switch': '',
    'Smart Lighting': '',
    'Home Safe': '',
    'Intercom': '',
    'Sensor': '',
    'Smart TV': '',
    'Standard TV': '',
    'TV Mount': '',
    'Remote': '',
    'Streaming Device': '',
    'TV Accessories': '',
    'Virtual Reality Headset': '',
    '3D Glasses': '',
    '3D Modulator': '',
    'Projector': '',
    'Projector Screen': '',
    'Projector Mount': '',
    'Projector Accessories': '',
    'TV Part': '',
    'TV Remote': '',
    'Set Top Box': '',
    'TV Stand': '',
    'Video Player': '',
    'Digital Photo Frame': '',
    'Home Theatre Projector': '',
    'Video Player Accessories': '',
    'Smart Band': '',
    'Smart Glasses': '',
    'Watch Strap Band': '',
    'Wearable Accessories': '',

    'Air Purifier': '',
    'Dehumidifier': '',
    'Humidifier': '',
    'Air Cleaner Accessories': '',
    'Air Conditioner': '',
    'Air Conditioner Accessories': '',
    'Air Cooler': '',
    'Trimmer': '',
    'Shaver': '',
    'Epilator': '',
    'Hair Straightener': '',
    'Hair Dryer': '',
    'Hair Curler': '',
    'Hair Crimper': '',
    'Electric Brush': '',
    'Electric Iron': '',
    'Electric Sewing Machine': '',
    'Water Heater': '',
    'Heater Cables': '',
    'Air Heater': '',
    'Coffee Maker': '',
    'Beverage Maker': '',
    'Roti Maker': '',
    'Induction Cooktop': '',
    'Sandwich Maker': '',
    'Electric Cooker': '',
    'Electric Kettle': '',
    'Microwave Oven': '',
    'OTG': '',
    'Toaster': '',
    'Electric Air Fryer': '',
    'Cooking Appliance Accessories': '',
    'Coffee Grinder': '',
    'Food Processor': '',
    'Pasta Maker': '',
    'Food Processor Accessories': '',
    'Blender': '',
    'Juicer': '',
    'Mixer Grinder': '',
    'Wet Grinder': '',
    'Dishwasher': '',
    'Dishwasher Accessories': '',
    'Electric Chimney': '',
    'Kitchen Accessories': '',
    'Freezer': '',
    'Refrigerator': '',
    'Refrigerator Accessories': '',
    'Vacuum Cleaner': '',
    'Vacuum Cleaner Parts and Accessories': '',
    'Washing Machine': '',
    'Washing Machine Accessories': '',
    'Water Purifier': '',
    'Water Cooler': '',
    'Water Dispenser': '',
    'Water Purifier Service Kit': '',
    'Water Purifier Filter': '',
    'Water Purifier Candle': '',
    'Water Purifier Pipe': '',
    'Water Purifier Accessories': '',
    'Water Cooler Accessories': '',
    'Inverter': '',
    'Inverter Batteries': '',
    'Battery Tray': '',
    'Voltage Stabilizer': ''

  }

  function changeHandler(e: any) {
    if (e.target.name !== "status") {
      setptData((prev) => {
        return { ...prev, [e.target.name]: e.target.value }
      })
    } else {
      setptData((prev) => {
        return { ...prev, [e.target.name]: e.target.checked }
      })
    }
  }

  async function submitHandler() {
    if (ptData.name === "") {
      toast.error(("Product Type Name is required!"));
      return
    }

    try {
      setisSubmitting(true)
      const { data } = await axios({
        url: `${apiEndpoint}/addprotype`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: {
          ...ptData
        }
      })

      if (data.status === 200) {
        toast.success("Product Type Added");
        setisSubmitting(false);
        setptData({
          name: "",
          ondc_mapping: "",
          status: true
        })
      } else {
        toast.error("Error In Adding Product Type");
        setisSubmitting(false)
      }
    } catch (err) {
      toast.error("Error in API")
      setisSubmitting(false)
    }


  }


  async function updateHandler() {
    if (ptData.name === "") {
      toast.error(("Product Type Name is required!"));
      return
    }

    try {
      setisSubmitting(true)
      const { data } = await axios({
        url: `${apiEndpoint}/updateprotype`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: {
          ...ptData,
          id: params.id
        }
      })

      if (data.status === 200) {
        toast.success("Product Type Updated");
        setisSubmitting(false);

      } else {
        toast.error("Error In Updating Product Type");
        setisSubmitting(false)
      }
    } catch (err) {
      setisSubmitting(false)
      toast.error("Error in API")
    }


  }

  async function getProductTypeById() {
    try {

      const { data } = await axios({
        url: `${apiEndpoint}/getprotype`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: {
          id: params.id
        }

      })
      console.log(data)
      if (data.status === 200) {
        setptData({
          name: data.data[0].name,
          ondc_mapping: data.data[0].ondc_mapping,
          status: data.data[0].status,
        })
      } else {
        toast.error("Error In Loading ");

      }
    } catch (err) {

      toast.error("Error in API")
    }
  }
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [mandatoryAttrModalOpen, setmandatoryAttrModalOpen] = useState(false);
  const [file, setFile] = useState<any>(null);
  const handleImport = async () => {
    // console.log(file)
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        console.log(data)
        let abc = data.filter((item: any) => item.name !== null)
        let orders = abc.map((order: any) => ({
          name: order.name ?? "",
          ondc_mapping: order.ondc_mapping ?? "",
          product_category_name: order.product_category_name ?? ""
        }))
        let jsonData = {
          product_types: orders
        };
        console.log("jsonData", jsonData)
        sendDataToDb(jsonData)
      },
    });
  };
  const [mafile, setmaFile] = useState(null);
  const handleMAFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile)
    if (selectedFile && selectedFile.type === "text/csv") {
      setmaFile(selectedFile);
    } else {
      alert("Please select a valid CSV file.");
    }
  };
  const handleMAImport = async () => {
    const formData: any = new FormData();
    formData.append("file", mafile);
    console.log(formData);
    // return 
    let result = await axios({
      url: `${apiEndpoint}/import_mandate_attribute`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
        "Content-Type": "multipart/form-data"
      },
      data: formData


    })
    toast.success("Imported Successfully!");
    setmandatoryAttrModalOpen(false)
    console.log(result)
  };

  async function sendDataToDb(jsonData: any) {
    try {
      // console.log(jsonData)
      let result = await axios({
        url: `${apiEndpoint}/addProductTypeCsv`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`,
        },
        data: jsonData,

      })

      if (result.status === 200) {
        // console.log("success")
        toast.success("Product Types added successfully !");
        setFile(null)
        let inputelement: any = document.getElementById('inputFile');
        inputelement.value = ''
      } else {
        // console.log("this is from succes if")
        toast.error("403 error !");
      }
    } catch (error) {
      toast.error("403 error !");
    }
  }

  function handleShipementModalClose() {
    setshipmentModalOpen(false)
  }
  function handleMAModalClose() {
    setmandatoryAttrModalOpen(false)
  }

  async function getSampleCsv() {
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/download_product_type_csv`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('us_st_d')}`
        }
      })
      if (data && (data.status === 200 || data.status === "200" || Number(data.status) === 200)) {
        const headers = Object.keys(data?.data[0]);
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data?.data, { header: headers });
        XLSX.utils.book_append_sheet(wb, ws, 'Orders');
        const fileName = `Sample_Product_Type_${new Date().getTime()}.csv`;
        XLSX.writeFile(wb, fileName);
      }
    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }
  }

  return (
    <ThemeMaker>
      <div className="row w-100 m-0 mt-1">
        <div className="col-md-12 ">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center">
                Add Product Type
                <div className="d-flex" style={{ gap: "20px" }}>

                  <button className="text-center btn btn-secondary" onClick={() => {
                    setmandatoryAttrModalOpen(true)
                  }}>Import Madatory Attributes</button>

                  <button className="text-center btn btn-secondary" onClick={() => {
                    setshipmentModalOpen(true)
                  }}>Import</button>

                  <Link to="/producttypelist" >
                    <button className="btn btn-primary">Product Type List</button>
                  </Link>
                </div>



              </h6>
            </div>

            <div className="card-body" style={{ minHeight: "70vh" }}>

              <div className="row">

                <div className="col-md-6">
                  <div className="form-group">
                    <label >
                      Name
                      <span className="text-danger" > * </span>

                    </label>
                    <input type="text" name="name" value={ptData.name} className="form-control" placeholder="Enter Product Type Name" onChange={changeHandler} />
                  </div>

                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label >
                      ONDC Mapping

                      {/* <span className="text-danger" > * </span> */}

                    </label>
                    <input type="text" className="form-control" name="ondc_mapping" placeholder="Enter ONDC Mapping" value={ptData.ondc_mapping}
                      onChange={changeHandler}
                    />
                  </div>

                </div>

                <div className="col-md-12 p-0">
                  <div className="col-md-3">

                    <div className="d-flex p-0 m-0 align-items-center" style={{ gap: "10px" }}>
                      <label className="m-0 p-0">
                        Status

                      </label>
                      <input type="checkbox" className="m-0 p-0" name="status"
                        checked={ptData.status}
                        onChange={changeHandler}

                      />
                    </div>

                  </div>


                </div>

                <div className="col-md-12 mt-3">

                  {params.id === undefined ? <button className="btn btn-primary" onClick={submitHandler} >{isSubmitting ? "Please Wait" : "Submit"}</button> : <button className="btn btn-success" onClick={updateHandler} >{isSubmitting ? "Please Wait" : "Update"}</button>}

                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {/*  <Modal
            open={shipmentModalOpen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">
              <div className="modal-content ">
                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                  <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Import Using Excel File..</p>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="importingSection">
                    <input type="file" id="inputFile" onChange={(e: any) => {
                      setFile(e.target.files[0]);
                    }} />


                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={handleImport} > Submit </button>
                </div>
              </div>
            </Box>
          </Modal>
*/}
          <Modal
            open={shipmentModalOpen}
            // onClose={handleShipementModalClose}  
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">

              <div className="modal-content ">
                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                  <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Import Using Excel File..
                    <span className="text-primary ml-2" style={{ cursor: "pointer" }} onClick={getSampleCsv}>Download Sample CSV</span></p>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="importingSection">
                    <input type="file" id="inputFile" onChange={(e: any) => {
                      setFile(e.target.files[0]);
                    }} />


                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={handleImport} > Submit </button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={mandatoryAttrModalOpen}
            // onClose={handleShipementModalClose}  
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">

              <div className="modal-content ">
                <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                  <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Import Using CSV File..
                    {/* <span className="text-primary ml-2" style={{ cursor: "pointer" }} onClick={getSampleCsv}>Download Sample CSV</span> */}
                  </p>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleMAModalClose} /></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="importingSection">
                    <input type="file" id="inputFile" onChange={(e: any) => {
                      handleMAFileChange(e)
                    }} />


                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary" onClick={handleMAImport} > Submit </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      </div>

    </ThemeMaker>
  )
}
